import React from 'react';
import img1 from '../Assets/images/B3.jpg';
import img2 from '../Assets/images/B4.jpg';
import img3 from '../Assets/images/B5.jpg';
import img4 from '../Assets/images/B6.jpg';
import img5 from '../Assets/images/B7.jpg';
import img6 from '../Assets/images/B8.jpg';
import img7 from '../Assets/images/B9.jpg';
import img8 from '../Assets/images/Software_Box_Mockup_1.jpg';
import img9 from '../Assets/images/Software_Box_Mockup_2.jpg';
import img10 from '../Assets/images/RITUALS.jpg'; 
import book from '../Assets/E-BOOK(PDF).pdf';
import video from '../Assets/video/Lp-2_Final.mp4';
import {FaWhatsappSquare, FaYoutube} from 'react-icons/fa';
import {AiFillFacebook, AiFillInstagram} from 'react-icons/ai';

function Thankyou() {

  const data = [
    {
      'url': img8,
      'title': 'Top One Percent Model'
    },
    {
      'url': img10,
      'title': 'Top One Percent Student Rituals'
    },
    {
      'url' : img1,
      'title':'Gratitude Enhancement Blueprint',
    },
    {
      'url': img2,
      'title': "Digital Diet Formula"
    },
    {
      'url': img3,
      'title': 'Time Management Mastery'
    },
    {
      'url': img4,
      'title': 'Creative Thinkers Blueprint'
    },
    {
      'url': img5,
      'title':'First Career Preparation'
    },
    {
      'url': img6,
      'title': 'Book Writing Mastery'
    },
    {
      'url': img7,
      'title': 'Behavioral Economics Formula'
    },
    {
      'url': img9,
      'title': 'Career Identification Formula'
    }
  ]

  return (
    <div className=' py-16'>
        <h1 className=' lg:text-7xl md:text-4xl text-2xl font-bold text-center'>CONGRATS!<br/><span className=' lg:text-6xl md:text-6xl text-4xl capitalize'>For your successful registeraton!!!</span></h1>
        <div className='flex flex-col justify-center items-center gap-5 pt-10 pb-8 mx-5'>
          <a href={book} target='null' ><div className=' py-3 px-8 bg-slate-900 rounded-xl text-white lg:text-2xl md:text-2xl text-xl'>Download your free e-book</div></a>
          <div className=' relative px-5 md:px-40 lg:px-40 p-5 transition-all ease-in-out hover:-translate-y-2 hover:scale-105  duration-500'>
            <video className={`rounded-lg shadow-xl w-[800px] transition-all duration-300`} h-auto controls>
              <source className='w-[400px] h-auto shadow-xl' src={video} type="video/mp4"/>
            </video>
          </div>
          <div className='flex flex-col lg:mx-20 justify-center items-center pt-10 text-slate-900 lg:gap-5 gap-3'>
          <a href='https://chat.whatsapp.com/GSi96O1zLhpKNS33QjXEeH' target='blank'>
          <div className='rounded-lg lg:w-96 lg:h-32 md:w-96 md:h-32 w-56 h-24 flex flex-row justify-center items-center lg:text-left md:text-left text-left px-5 bg-blue-100 lg:gap-3 gap-2'>
            <div className='text-[40px] lg:text-[50px] md:text-[50px]'><FaWhatsappSquare /></div>
            <p className='text-xl'>Click to join WhatsApp group</p>
          </div>
          </a>
          <a href='https://www.facebook.com/groups/revamplab/' target='blank'>
          <div className='rounded-lg lg:w-96 lg:h-32 md:w-96 md:h-32 w-56 h-24 flex flex-row justify-center items-center lg:text-left md:text-left text-left px-5 bg-blue-100 lg:gap-3 gap-2'>
            <div className='text-[40px] lg:text-[50px] md:text-[50px]'><AiFillFacebook /></div>
            <p className='text-xl'>Click to join Facebook VIP Group</p>
          </div>
          </a>
          <a href='https://www.instagram.com/eldo_geoege_varghese?igsh=eHNjNHk4azlrd2xn' target='blank'>
          <div className='rounded-lg lg:w-96 lg:h-32 md:w-96 md:h-32 w-56 h-24 flex flex-row justify-center items-center lg:text-left md:text-left text-left px-5 bg-blue-100 lg:gap-3 gap-2'>
            <div className='text-[40px] lg:text-[50px] md:text-[50px]'><AiFillInstagram /></div>
            <p className='text-xl'>Click to visit our Instagram page</p>
          </div>
          </a>
          <a href='https://www.youtube.com/@eldogeorgevarghese5016' target='blank'>
          <div className='rounded-lg lg:w-96 lg:h-32 md:w-96 md:h-32 w-56 h-24 flex flex-row justify-center items-center lg:text-left md:text-left text-left px-5 bg-blue-100 lg:gap-3 gap-2'>
            <div className='text-[40px] lg:text-[50px] md:text-[50px]'><FaYoutube /></div>
            <p className='text-xl'>Click to Visit our youtube channel</p>
          </div>
          </a>
        </div>
          <p className='text-xl pt-5'>Wait ! before you go in the webinar</p>
          <div className='flex flex-wrap justify-center gap-5'>
            {data.map((item, index) => ( 
              <div key={index} className='lg:w-80 md:w-80 w-72 flex flex-col items-center overflow-hidden bg-slate-900 rounded-xl'> 
                <img src={item.url} alt={item.title} className='w-80 h-auto' /> 
                <p className='text-white text-2xl py-5 px-2'>{item.title}</p> 
                {/* <div className='flex justify-center items-center content-center align-middle flex-col'>
                </div> */}
              </div> 
            ))}
          </div>
        </div>
        
    </div>
  )
}

export default Thankyou