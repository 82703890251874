import React from 'react';

function About() {
  return (
    <div className='py-20 lg:px-20 md:px-12 px-5 flex flex-col justify-center items-center gap-10 bg-blue-200'>
        <div className=''>
            <div className=' text-5xl font-bold text-slate-900'>Hi , I am Eldo George Varghese</div>
            <div className=' text-2xl pt-8 lg:px-20 md:px-12 px-5'>I have trained more than 2000 plus students in achieving the dream career that they dream off, because selecting a career is so easy, but attain the desired heights in the career is something that makes your child unique in there journey.. </div>
        </div>
    </div>
  )
}

export default About