import React, { useState } from 'react';
import video from '../Assets/video/Lp-1_Final.mp4';

function Register() {

        const [isOpen, setIsOpen] = useState(false);
        const [name, setname] = useState('')
        const [email, setemail] = useState('')
        const [number, setnumber] = useState('')

        const toggleForm = () => {
          setIsOpen(!isOpen);
        };

        const closeForm = () => {
            setIsOpen(false);
        }

    async function handlesubmit(e){
        e.preventDefault();
        const datas = {
            name,
            email,
            number
        }

        fetch("https://sheetdb.io/api/v1/8p1gfn767w5ip",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: [
                    {
                        'Name': datas.name,
                        'Email': datas.email,
                        'Phone': datas.number,
                    }
                ]
            })
        }).then((res)=>res.json)
        .then((data)=> {
            alert('join the whatsApp group')
            window.location.href='/success'
            setname('')
            setemail('')
            setnumber('')
        })
        .catch((error)=>{
            console.log(error)
        })

    // https://webhooks.integrately.com/a/webhooks/dc0e0740996846748d95dec716d57364
    // console.log(data)
    }


  return (
    <div className='flex flex-col justify-center items-center mx-10 lg:mx-20 pb-20'>
        <div className='flex flex-row flex-wrap gap-5 justify-center items-center '>
            <div className=' group relative overflow-hidden bg-blue-300 w-[310px] md:w-[600px] lg:w-[650px] h-[370px] shadow-xl rounded-xl text-left flex flex-col justify-center items-center px-8 transition-all ease-in-out hover:-translate-y-2 hover:scale-105  duration-500'>
                <svg className=' absolute saturate-0 transition-all group-hover:saturate-100 duration-500' xmlns='http://www.w3.org/2000/svg'  width='1500' height='1500' viewBox='0 0 1000 1000'><rect fill='#00293F' width='800' height='800'/><g fill='none' stroke='#053A44'><path d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/><path d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/><path d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/><path d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/><path d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/></g><g  fill='#043B55'><circle  cx='769' cy='229' r='5'/><circle  cx='539' cy='269' r='5'/><circle  cx='603' cy='493' r='5'/><circle  cx='731' cy='737' r='5'/><circle  cx='520' cy='660' r='5'/><circle  cx='309' cy='538' r='5'/><circle  cx='295' cy='764' r='5'/><circle  cx='40' cy='599' r='5'/><circle  cx='102' cy='382' r='5'/><circle  cx='127' cy='80' r='5'/><circle  cx='370' cy='105' r='5'/><circle  cx='578' cy='42' r='5'/><circle  cx='237' cy='261' r='5'/><circle  cx='390' cy='382' r='5'/></g></svg>
                <div className=' relative text-white'>
                    <div className=' lg:text-4xl md:text-4xl text-2xl font-bold'>GET THE<br/><span className=' lg:text-8xl md:text-8xl text-5xl'>E-BOOK</span></div>
                    <div className='lg:text-3xl md:text-3xl text-xl'>download </div>
                    <button onClick={toggleForm} className='px-4 py-2 bg-blue-900 text-white rounded-xl w-40 mt-5 text-xl transition-all hover:bg-blue-300 hover:text-slate-900 duration-500'>Join Webinar</button>
                </div>
            </div>
            {/* form */}
            {isOpen && (
        <div className="fixed z-30 top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-75">
          <div className="bg-white rounded-lg p-8 w-[300px]">
            <h2 className="text-2xl font-bold mb-4">Register Now</h2>
            <form onSubmit={handlesubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                  Name
                </label>
                <input
                required
                onChange={(e)=>{setname(e.target.value)}}
                value = {name}
                  type="text"
                  id="name"
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
                  Email
                </label>
                <input
                required
                onChange={(e)=>{setemail(e.target.value)}}
                value = {email}
                  type="email"
                  id="email"
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="number" className="block text-gray-700 font-bold mb-2">
                  WhatsApp Number
                </label>
                <input
                required
                onChange={(e)=>{setnumber(e.target.value)}}
                value = {number}
                  type="text"
                  id="number"
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
                <div className=' flex gap-2 justify-center items-center'>
                    <button type="submit"className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                      Submit
                    </button>
                    <button onClick={closeForm} type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                      close
                    </button>
                </div>
            </form>
          </div>
        </div>
      )}
            <div className='relative transition-all w-[310px] md:w-[600px] lg:w-[650px] ease-in-out hover:-translate-y-2 hover:scale-105  duration-500 z-0'>
              <video className={`rounded-lg shadow-xl h-auto transition-all duration-300`} controls>
                <source className='shadow-xl' src={video} type="video/mp4"/>
              </video>
            </div>
        </div>
        <div className=' flex flex-wrap justify-center items-center lg:gap-20 gap-10 mt-20'>
            <div className=' w-80 rounded-xl py-10 px-10 bg-blue-300 text-slate-900 text-xl drop-shadow-sm font-semibold transition-all hover:bg-white hover:-translate-y-3 duration-500'>First Indian to head the asia and pacific alliance of ymca's</div>
            <div className=' w-80 rounded-xl py-10 px-10 bg-blue-300 text-slate-900 text-xl drop-shadow-sm font-semibold transition-all hover:bg-white hover:-translate-y-3 duration-500'>2000 plus students mentored</div>
            <div className=' w-80 rounded-xl py-10 px-10 bg-blue-300 text-slate-900 text-xl drop-shadow-sm font-semibold transition-all hover:bg-white hover:-translate-y-3 duration-500'>A mentor who is an implementer of things</div>
        </div>
    </div>
  )
}

export default Register