import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Main from './pages/main';
import Thankyou from './pages/thankyou';

function App() {
  return (
    <div className="App bg-blue-200 pt-5">
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Main/>} />
        <Route path='/success' element={<Thankyou/>} />
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
