import React from 'react';
import curious from '../Assets/icons/curious.png';
import career from '../Assets/icons/career.png';
import academics from '../Assets/icons/academics.png';

function Benefits() {
  return (
    <div className=' bg-slate-950 flex flex-col px-5 lg:gap-16 gap-10 justify-center items-center py-20'>
        <div className='text-5xl text-white font-bold'>In this webinar you will learn</div>
        <div className='flex flex-wrap lg:gap-20 gap-10 justify-center items-center'>
            <div className='flex flex-col justify-center items-center gap-5 bg-blue-100 px-10 py-10 lg:w-96 md:w-96 w-72 rounded-xl'>
                <img className='w-20 h-auto drop-shadow-lg' src={curious} alt='curious'/>
                <p className='text-xl font-semibold italic'>How To have the Curiosity mindset to achieve massive success</p>
            </div>
            <div className='flex flex-col justify-center items-center gap-5 bg-blue-100 px-10 py-10 lg:w-96 md:w-96 w-72 rounded-xl'>
                <img className='w-20 h-auto drop-shadow-lg' src={career} alt='career'/>
                <p className='text-xl font-semibold italic'>How to select the right career that makes your child a leader in the future</p>
            </div>
            <div className='flex flex-col justify-center items-center gap-5 bg-blue-100 px-10 py-10 lg:w-96 md:w-96 w-72 rounded-xl'>
                <img className='w-20 h-auto drop-shadow-lg' src={academics} alt='academics'/>
                <p className='text-xl font-semibold italic'>How the career could be linked with academics so that you child enjoys every moment of there life </p>
            </div>
        </div>
    </div>
  )
}

export default Benefits