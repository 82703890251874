import React, { useState, useEffect } from 'react'
import video from '../Assets/video/hero.mp4'
import image from '../Assets/images/19 (1).jpg'

function Hero() {
  const [imageOpacity, setImageOpacity] = useState(0)
  const [xpop, setxpop] = useState('translateY(50px)')

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setImageOpacity(1)
      setxpop('translateY(0)')
    }, 100) // adjust the delay as needed

    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <div className=' relative lg:px-20 px-5 lg:mx-10 mx-0 my-5 flex flex-col bg-slate-900 text-white py-20 lg:gap-20 gap-5 lg:rounded-[70px] rounded-xl overflow-hidden drop-shadow-xl' >
      <video
        autoPlay
        loop
        muted
        playsInline
        className="w-full absolute h-full object-cover inset-0 opacity-20"
      >
        <source  style={{ opacity: imageOpacity, transform: xpop }} className='transition-all ease-in-out duration-500' src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div style={{ opacity: imageOpacity, transform: xpop }} className='transition-all ease-in-out duration-500 relative text-5xl text-red-600 font-bold text-left lg:text-center md:text-center'>Attention : If you want to make yourself ready with the changing world , this webinar is for you</div>

      <div style={{ opacity: imageOpacity, transform: xpop }} className='transition-all ease-in-out duration-500 relative flex flex-row flex-wrap gap-10 justify-center items-center' >
        <div className='flex flex-col justify-center text-left lg:w-3/5' >
          <div className=' text-2xl'><span className=' lg:text-7xl md:text-7xl text-5xl font-extrabold text-blue-300'>Discover my <br/>“Secret Career Identification Model“</span><br/>that has turned that has turned normal students into top one percent student group.</div>
        </div>
        <div className='lg:w-1/3 lg:h-[450px] overflow-hidden rounded-xl' >
          <img
            style={{ opacity: imageOpacity, transform: xpop }}
            className='w-[300px] h-auto lg:w-[500px] transition-all ease-in-out duration-500'
            src={image}
            alt='pic'
          />
        </div>
      </div>
    </div>
  )
}

export default Hero