import React from 'react'
import Hero from '../Components/hero'
import About from '../Components/about'
import Register from '../Components/register'
import Benefits from '../Components/benefits'
import Navbarss from '../Components/navbar'

function Main() {
  return (
    <div>
        <Navbarss/>
        <Hero/>
        <About/>
        <Register/>
        <Benefits/>
    </div>
  )
}

export default Main